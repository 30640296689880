.projects {
  margin: 700px auto 300px;
  padding: 0 100px 0;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
}

.projects__title {
  padding: 0;
  display: flex;
  margin: 0;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
  font-size: 32px;
  font-weight: 600;
  color: #bacaff;
  position: relative;
}

.projects__title-li::marker {
  color: #00ff94;
}

.projects__title-li::before {
  content: "";
  width: 2px;
  display: inline-block;
}

.projects__title:after {
  content: "";
  display: block;
  position: relative;
  width: 300px;
  top: 16px;
  height: 1px;
  margin-left: 20px;
  background-color: #8f9fd6;
  opacity: 40%;
}

.projects__content {
  max-width: 1200px;
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  padding-top: 100px;
}

@media screen and (max-width: 1400px) {
  .projects {
    padding: 50px 0 0;
  }

  .projects__content {
    gap: 60px 30px;
    padding-top: 100px;
  }
}

@media screen and (max-width: 860px) {
  .projects {
    margin-top: 300px;
  }
  .projects__title:after {
    width: 240px;
  }
}

@media screen and (max-width: 660px) {
  .projects__content {
    padding-top: 50px;
  }

  .projects__title {
    font-size: 26px;
    overflow: hidden;
  }

  .projects__title:after {
    content: "";
    display: none;
    position: relative;
    width: 100%;
  }
}
