.footer {
  padding: 50px 0 10px;
  margin: auto;
  font-size: 16px;
}

.footer__text {
  text-align: center;
  margin: 0;
  padding: 5px 0 0;
}

.footer__link {
  text-decoration: none;
  color: #8f9fd6;
  transition: color ease-in-out 0.15s;
}

.footer__link:hover {
  color: #00ff94;
}
