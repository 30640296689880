.about {
  margin: 700px auto 700px;
  padding: 20px 20px 0;
  max-width: fit-content;
}

.about__title {
  padding: 0;
  display: flex;
  margin: 0;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
  font-size: 32px;
  font-weight: 600;
  color: #bacaff;
  position: relative;
}

.about__title-li::marker {
  color: #00ff94;
}

.about__title-li::before {
  content: "";
  width: 2px;
  display: inline-block;
}

.about__title:after {
  content: "";
  display: block;
  position: relative;
  width: 300px;
  top: 16px;
  height: 1px;
  margin-left: 20px;
  background-color: #8f9fd6;
  opacity: 40%;
}

.about__content {
  max-width: 900px;
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: 2fr 2fr;
  gap: 0 40px;
}

.about__desc {
  max-width: 480px;
  margin-right: auto;
}

.about__text {
  margin: 25px 0;
}

.about__list {
  list-style-type: disclosure-closed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 0;
  max-width: 300px;
}

.about__list-item::marker {
  color: #00ff94;
  font-size: 14px;
}

.about__pic-wrapper {
  margin-top: 24px;
  max-height: 300px;
  max-width: 300px;
  position: relative;
  transition: translate ease-in 0.1s;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.about__pic-wrapper::after {
  width: 100%;
  height: 100%;
  content: "";
  border: 3px #00ff94 solid;
  border-radius: 5px;
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: -1;
  transition: translate ease-in 0.1s;
}
.about__pic-wrapper:hover {
  translate: -5px -5px;
}

.about__pic-wrapper:hover::after {
  translate: 10px 10px;
}

.about__overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: #00ff94;
  z-index: 2;
  border-radius: 5px;
  opacity: 0.5;
  transition: opacity ease-in 0.1s;
}

.about__overlay:hover {
  opacity: 0;
}

.about__pic {
  max-width: 300px;
  max-height: 300px;
  border-radius: 5px;
  z-index: 1;
}

@media screen and (max-width: 1074px) {
  .about__content {
    font-size: 18px;
  }

  .about__pic {
    width: 200px;
    height: 200px;
  }
  .about__pic-wrapper {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 900px) {
  .about__desc {
    max-width: 100%;
  }
}

@media screen and (max-width: 710px) {
  .about {
    margin-top: 300px;
    margin-bottom: 300px;
    padding: 40px 0 0;
  }
  .about__title {
    font-size: 26px;
    overflow: hidden;
  }

  .about__title-li::before {
    content: "";
    width: 2px;
    display: inline-block;
  }

  .about__title:after {
    content: "";
    display: block;
    position: relative;
    width: 30%;
  }

  .about__content {
    font-size: 18px;
  }
}
