.social {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

.social__icon {
  transition: fill ease-in 0.15s, transform ease-in 0.1s;
  fill: #fff;
  padding-bottom: 5px;
}

.social__icon:hover {
  transform: translateY(-5px);
  fill: #00ff94;
}
