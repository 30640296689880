.app {
  background-color: #0a192f;
  min-height: 100vh;
  color: #8f9fd6;
  font-family: "Titillium Web";
  font-weight: 400;
  font-size: 20px;
  position: relative;
}

.app__content {
  max-width: 1400px;
  margin: 25vh auto 0;
  min-height: 100vh;
  padding: 20px 10% 20px;
}

@media screen and (max-width: 800px) {
  .app__content {
    margin-top: 15vh;
  }
}

@media screen and (max-width: 500px) {
  .app__content {
    margin-top: 80px;
  }
}
