.header {
  max-width: 100%;
  font-size: 20px;
  margin: 0 10% 0 10%;
}

.header__intro {
  color: #00ff94;
  letter-spacing: 2px;
  margin: 0 0 15px;
  font-weight: 600;
}

.header__title {
  margin: 0;
  font-size: clamp(40px, 8vw, 84px);
  font-weight: 900;
  color: #bacaff;
}

.header__desc {
  margin: 15px 0 0;
  font-size: 30px;
  word-spacing: 6px;
}

.header__desc_alt {
  margin: 40px 0 0;
  font-size: 22px;
  max-width: 550px;
  line-height: 1.5;
}

@media screen and (max-width: 320px) {
  .header__desc {
    font-size: 24px;
  }
  .header__desc_alt {
    font-size: 18px;
  }
}
