.card {
  width: 300px;
  background-color: #112240;
  border-radius: 7px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: auto;
  position: relative;
  transition: translate 0.2s linear;
}

.card:hover {
  translate: 0 -5px;
}
.card__wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.card__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0;
  z-index: 2;
  transition: opacity linear 0.15s;
}

.card__overlay:hover {
  opacity: 0.5;
}

.card__image {
  width: 100%;
  height: 220px;
  display: block;
  z-index: 1;
  overflow: hidden;
  object-fit: cover;
}
.card__icon {
  position: absolute;
  fill: #fff;
  transition: fill ease-in 0.15s;
  right: 0;
}

.card__icon:hover {
  fill: #00ff94;
  cursor: pointer;
}

.card__link {
  color: #8f9fd6;
  text-decoration: none;
}

.card__info {
  display: flex;
  height: 100px;
  padding: 25px 15px 20px 15px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.card__title {
  margin: 0;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.21;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card__description {
  margin: 0;
  padding-top: 5px;
  font-size: 16px;
  max-width: 80%;
}

@media screen and (max-width: 400px) {
  .card__title {
    font-size: 22px;
  }

  .card__description {
    text-align: center;
  }
}
