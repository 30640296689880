.contact {
  margin: 300px auto 250px;
  padding: 100px 100px 0;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__title {
  padding: 0;
  display: flex;
  margin: 0;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
  font-size: 20px;
  font-weight: 600;
  color: #00ff94;
  position: relative;
}

.contact__title-li::marker {
  color: #00ff94;
}

.contact__header {
  font-size: 48px;
  color: #bacaff;
  margin-bottom: 0;
}

.contact__header:last-of-type {
  margin-top: 0;
}

.contact__header_alt {
  color: #00ff94;
}

.contact__description {
  max-width: 450px;
  text-align: center;
  margin-bottom: 45px;
}

.contact__link {
  text-decoration: none;
  cursor: pointer;
  color: #00ff94;
  padding: 20px;
  border: #00ff94 3px solid;
  border-radius: 5px;
  position: absolute;
  bottom: -75px;
}

.contact__icons {
  margin: auto;
  max-width: 200px;
  display: none;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  bottom: -160px;
}

.shake:hover {
  animation: shake 0.5s infinite;
  animation-timing-function: linear;
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(2deg);
  }
}

@media screen and (max-width: 768px) {
  .contact__icons {
    display: grid;
  }
}

@media screen and (max-width: 620px) {
  .contact {
    padding: 100px 10px 0;
  }

  .contact__header {
    font-size: 36px;
    text-align: center;
  }

  .contact__description {
    font-size: 18px;
  }

  .contact__link {
    font-size: 18px;
    padding: 16px;
  }
}
