.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 30px;
}

.nav__logo {
  width: 48px;
  height: 48px;
  background-image: url("../../images/S-logo-hex-sm.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.nav__content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav__list {
  padding: 0;
  display: flex;
  margin: 0;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
}

.nav__li {
  padding: 10px;
}

.nav__li::marker {
  color: #00ff94;
}

.nav__link {
  text-decoration: none;
  color: #8f9fd6;
  margin-left: 5px;
  transition: color ease-in-out 0.15s;
}

.nav__link:hover {
  color: #00ff94;
}

.nav__resume {
  color: #00ff94;
  padding: 8px 15px;
  border: solid 1px #00ff94;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color ease-in-out 0.15s;
}

.nav__resume:hover {
  background-color: rgba(0, 255, 148, 0.15);
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #00ff94;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media screen and (max-width: 500px) {
  .nav {
    justify-content: space-between;
    padding: 10px;
  }

  .nav__content {
    gap: 0;
    width: 100%;
    justify-content: flex-end;
  }

  .nav__list {
    flex-direction: column;
    margin-right: 20px;
  }

  .nav__li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
